<template>
  <div>
    <b-card
      no-body
      class="mb-0"
    >
      <div class="m-2">
        <!-- Table Top -->
        <b-row>
          <!-- Per Page -->
          <b-col
            cols="12"
            md="3"
            class="d-flex align-items-center justify-content-start mb-1 mb-md-0"
          >
            <label>{{ $t('Lists.Show') }}</label>
            <v-select
              v-model="perPage"
              :options="perPageOptions"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              :clearable="false"
              class="per-page-selector d-inline-block mx-50"
            />
            <label>{{ $t('Lists.Entries') }}</label>
          </b-col>
          <b-col
            cols="12"
            md="5"
          >
            <div class="d-flex align-items-center justify-content-end">
              <b-form-input
                v-model="searchQuery"
                class="d-inline-block mr-1"
                :placeholder="$t('Lists.Search')"
              />
            </div>
          </b-col>
        </b-row>
      </div>
      <b-table
        ref="reftotalBondenWarehouseTable"
        :sort-by.sync="sortBy"
        class="position-relative"
        :items="refetchBondenWarehouses"
        responsive
        :fields="tableColumns"
        primary-key="id"
        show-empty
        :empty-text="$t('Lists.NoMatchingRecordsFound') "
        :sort-desc.sync="isSortDirDesc"
      >
        <!-- Actions -->
        <template #cell(actions)="data">
          <b-dropdown
            variant="link"
            no-caret
            :right="$store.state.appConfig.isRTL"
          >

            <template #button-content>
              <feather-icon
                icon="MoreVerticalIcon"
                size="16"
                class="align-middle text-body"
              />
            </template>
            <!-- Edit  -->
            <b-dropdown-item
              :to="{ name: 'apps-bonden-warehouse-edit', params: { recintoFiscalId: data.item.id } }"
            >
              <feather-icon icon="EditIcon" />
              <span class="align-middle ml-50">{{ $t('Lists.Edit') }}</span>
            </b-dropdown-item>
          </b-dropdown>
        </template>
      </b-table>
      <div class="mx-2 mb-2">
        <b-row>
          <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-start"
          >
            <span class="text-muted">{{ $t('Lists.Showing') }} {{ dataMeta.Showing }} {{ $t('Lists.To') }} {{ dataMeta.to }} {{ $t('Lists.Of') }} {{ dataMeta.of }} {{ $t('Lists.Entries') }}</span>
          </b-col>
          <!-- Pagination -->
          <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-end"
          >
            <b-pagination
              v-model="currentPage"
              :total-rows="totalBondenWarehouses"
              :per-page="perPage"
              first-number
              last-number
              class="mb-0 mt-1 mt-sm-0"
              prev-class="prev-item"
              next-class="next-item"
            >
              <template #prev-text>
                <feather-icon
                  icon="ChevronLeftIcon"
                  size="18"
                />
              </template>
              <template #next-text>
                <feather-icon
                  icon="ChevronRightIcon"
                  size="18"
                />
              </template>
            </b-pagination>
          </b-col>
        </b-row>
      </div>
    </b-card>
  </div>
</template>
<script>
import {
  BCard,
  BRow,
  BCol,
  BFormInput,
  BTable,
  BDropdown,
  BDropdownItem,
  BPagination,
} from 'bootstrap-vue'
import { ref, watch, computed } from '@vue/composition-api'
import vSelect from 'vue-select'
import generalDataService from '@/services/generalData.service'
import i18n from '@/libs/i18n'
// import BaseListEdit from '@/views/components/base-list-edit/BaseListEdit.vue'

export default {
  components: {
    BCard,
    BRow,
    BCol,
    BFormInput,
    BTable,
    vSelect,
    BDropdown,
    BDropdownItem,
    BPagination,
    // BaseListEdit,

  },
  setup() {
    /* Refs */
    const reftotalBondenWarehouseTable = ref(null)
    /* Services */
    const { fetchBondenWarehouses } = generalDataService()
    /* Pagination */
    const perPageOptions = [10, 25, 50, 100]
    const perPage = ref(10)
    const currentPage = ref(1)
    /* Params */
    const status = ref('')
    const searchQuery = ref('')
    const isSortDirDesc = ref(false)
    const sortBy = ref('aduanaId')
    /* Data */
    const totalBondenWarehouses = ref(0)
    const records = ref([])
    const tableColumns = [
      {
        label: i18n.t('AppBondenWarehouse.columns.keyCode'),
        key: 'clave',
      },
      {
        label: i18n.t('AppBondenWarehouse.columns.name'),
        key: 'texto',
      },
      {
        label: i18n.t('AppBondenWarehouse.columns.nickname'),
        key: 'alias',
      },
      {
        label: i18n.t('AppBondenWarehouse.columns.custom'),
        key: 'aduana.nombre',
      },
      {
        label: i18n.t('AppBondenWarehouse.columns.Enabled'),
        key: 'habilitado',
      },
      {
        label: i18n.t('Lists.Actions'),
        key: 'actions',
      },
    ]
    const refetchBondenWarehouses = (ctx, callback) => {
      fetchBondenWarehouses({
        searchQuery: searchQuery.value,
        limit: perPage.value,
        offSet: currentPage.value === 1 ? 0 : (currentPage.value * perPage.value) - perPage.value,
        sort: `${sortBy.value} ${isSortDirDesc.value ? 'ASC' : 'DESC'}`,
        estado: status.value,
      }, data => {
        records.value = data.recintoFiscal
        totalBondenWarehouses.value = data.total
        callback(records.value)
      })
    }
    /* UI */

    const dataMeta = computed(() => {
      const localItemsCount = reftotalBondenWarehouseTable.value ? reftotalBondenWarehouseTable.value.localItems.length : 0
      return {
        from: perPage.value * (currentPage.value - 1) + (localItemsCount ? 1 : 0),
        to: perPage.value * (currentPage.value - 1) + localItemsCount,
        of: totalBondenWarehouses.value,
      }
    })
    const refreshData = () => {
      reftotalBondenWarehouseTable.value.refresh()
    }
    watch([currentPage, perPage, status, searchQuery], () => {
      refreshData()
    })
    return {
      // Ref
      reftotalBondenWarehouseTable,
      // Params
      searchQuery,
      sortBy,
      refetchBondenWarehouses,
      isSortDirDesc,
      // Data
      tableColumns,
      totalBondenWarehouses,
      records,
      // UI
      dataMeta,
      // Pagination
      perPageOptions,
      perPage,
      currentPage,
      status,
      // Event
      refreshData,
    }
  },
}
</script>
<style lang="scss" scoped>

</style>
